import React from 'react'
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Webr from '../../components/Home/Home/Webr';


const Main = () => {

  const { t } = useTranslation()

  return (
    <>

    <Helmet>
    <title>{t('pagehometitle')}</title>
    <meta name='description' content='Hidrolik lift  Anasayfa'/>
    <meta name="keywords" content="Hidrolik lift"/>
    </Helmet>

       <Webr />

    </>
  )
}

export default Main