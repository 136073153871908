import React from 'react'
import logo from './dev.gif'
function Webr() {
  return (

    <section className='container'>
    
    
     <div className='imgwebr'>
     <img src={logo} alt="Dora Hidrolik"/>
     </div> 

  
    </section>
  )
}

export default Webr
