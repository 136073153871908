import React, { useEffect, useState } from 'react'
import {  Route, Routes, useLocation } from 'react-router-dom'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import Home from '../Pages/Home/Home'
import Contact from '../Pages/Contact/Contact'
import NotFound from '../Pages/404/NotFound'




const Layout = () => {
    const [homepage, sethomepage] = useState(false);
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/home"){
        sethomepage(false);
      }else{
        sethomepage(true);
      }
    }, [location]);
    
    return (
        <>
      
            {homepage && <Navbar />}
            
            <Routes>
            <Route> 
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                </Route>
                <Route path='*' element={<NotFound />} />
                
            </Routes>
            <Footer />
           
           
        </>
    )
}

export default Layout