import React from 'react'

function Map() {
    return (
        <>
            <div className="google-map__home-two">
                <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3206325.1860282477!2d27.88171134653501!3d38.29856807378817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08f102e9d6ad5%3A0x1d525b6376f67e92!2sDora%20Hidrolik!5e0!3m2!1str!2str!4v1709235444290!5m2!1str!2str" className="map__home-two" allowFullscreen></iframe>
            </div>
        </>
    )
}

export default Map